import { ResponseList } from '@/interface/commonInterface'
import request from './config/request'
import type { UserDetail } from '@/interface/userInterface'
import liff from '@line/liff'

const liffId = process.env.REACT_APP_LIFF_ID || ''

export function login(payload: object | {}) {
    const url = '/auth/register'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}

export function reInstall(payload: object | {}) {
    const url = '/auth/reinstall'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}

export const logout = () => {
    const url = '/auth/logout'
    return request({
        url,
        method: 'POST',
    })
}

export const me = () => {
    const url = '/auth/me'
    return request<UserDetail, UserDetail>({
        url,
        method: 'GET',
    })
}

export const getLiffAccessToken = async () => {
    try {
        const config: any = { liffId }
        await liff.init(config)
        return liff.getAccessToken()
    } catch (err) {
        console.log(err)
        return false
    }
}

export function profile(payload: object | {}) {
    const url = '/profile'
    return request<ResponseList, ResponseList>({
        url,
        method: 'PATCH',
        data: payload,
    })
}
