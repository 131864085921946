import { combineReducers } from '@reduxjs/toolkit'

import login from '../stores/login'
import history from '@/stores/history'
import shop from '@/stores/shop'
import purchase from '@/stores/purchase'
import pointPackage from '@/stores/pointPackage'
import userPoint from '@/stores/userPoint'

const rootReducer = combineReducers({
    login,
    history,
    shop,
    purchase,
    pointPackage,
    userPoint,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
