const profile = {
    title: 'Account',
    btn_sendmail: '忘れ物・トラブルを報告',
    btn_change_payment: '支払い方法の変更',
    btn_lost_item: '忘れ物を取りに戻る',
    implement: {
        title_modal: 'クレジットカード登録',
    },
    form: {
        popup_title: '会員情報登録',
        label: {
            first_name: 'お名前',
            first_name_hiragana: 'フリガナ',
            birthday: '生年月日',
            tel: '電話番号',
        },
        placeholder: {
            first_name: '姓',
            last_name: '名',
            first_name_hiragana: 'セイ',
            last_name_hiragana: 'メイ',
            birthday: '生年月日',
            tel: '電話番号',
        },
        btn_submit: '登録',
    },
}
export default profile
