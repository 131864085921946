import React from 'react'
import { RouterProvider } from 'react-router-dom'
import router from '@/router'
import { HelmetProvider } from 'react-helmet-async'

const App: React.FC = () => {
    const helmetContext = {}
    return (
        <div className="App">
            <HelmetProvider context={helmetContext}>
                <RouterProvider
                    router={router}
                    fallbackElement={<Fallback />}
                />
            </HelmetProvider>
        </div>
    )
}

export default App

export function Fallback() {
    return <p>Performing initial data load</p>
}
