import { useAppSelector } from '@/app/hooks'
import { ACCESS_TOKEN } from '@/utils/constants'
import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const AuthGuard: React.FC = ({ Componenent }: any) => {
    const location = useLocation()
    // const { accessToken } = useAppSelector((state) => state.login)
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    let auth = accessToken != null ? true : false

    useEffect(() => {
        if (!!accessToken) {
            auth = true
        }
    }, [location])

    return auth ? <Outlet /> : <Navigate to="/login" />
}

export default AuthGuard
