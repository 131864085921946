import React from 'react'
import logo from '@/assets/imgs/saunaxx_logo.png'
interface HeaderProps {
    title?: string
}

const Header: React.FC<HeaderProps> = ({ title = 'sauna' }) => {
    return (
        <section className="header-top">
            <img src={logo} alt="" />
        </section>
    )
}

export default Header
