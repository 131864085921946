import { ResponseList } from '@/interface/commonInterface'
import request from './config/request'

export const saunaxHistories = (payload: object | {}) => {
    const url = '/sauna-histories'
    return request<ResponseList, ResponseList>({
        url,
        method: 'GET',
        params: payload,
    })
}
