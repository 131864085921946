import Layout from '@/components/common/Layout'
import Loading from '@/components/common/Loading/Loading'
import * as Page from '@/pages'
import { createBrowserRouter } from 'react-router-dom'
import AuthGuard from './AuthGuard'
import ProtectedExit from './protected/ProtectedExit'
import ProtectedLostItemExit from './protected/ProtectedLostItemExit'
import ProtectedShop from './protected/ProtectedShop'

const router = createBrowserRouter([
    {
        element: <AuthGuard />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: '/',
                        element: <Page.ShopView />,
                        errorElement: <Loading />,
                    },
                    {
                        path: '/shops',
                        children: [
                            {
                                path: '',
                                element: (
                                    <ProtectedShop>
                                        <Page.ShopView />
                                    </ProtectedShop>
                                ),
                                errorElement: <Loading />,
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        element: (
                                            <ProtectedShop>
                                                <Page.ShopDetailView />
                                            </ProtectedShop>
                                        ),
                                        errorElement: <Loading />,
                                    },
                                    {
                                        path: 'entry',
                                        element: (
                                            <ProtectedShop>
                                                <Page.ShopEntryView />
                                            </ProtectedShop>
                                        ),
                                        errorElement: <Loading />,
                                    },
                                    {
                                        path: 'exit',
                                        element: (
                                            <ProtectedExit>
                                                <Page.ShopExitView />
                                            </ProtectedExit>
                                        ),
                                        errorElement: <Loading />,
                                    },
                                    // {
                                    //     path: 'lost-item-entry',
                                    //     element: (
                                    //         <ProtectedShop>
                                    //             <Page.ShopLostItemEntryView />
                                    //         </ProtectedShop>
                                    //     ),
                                    //     errorElement: <Loading />,
                                    // },
                                    // {
                                    //     path: 'lost-item-exit',
                                    //     element: (
                                    //         <ProtectedLostItemExit>
                                    //             <Page.ShopLostItemExitView />
                                    //         </ProtectedLostItemExit>
                                    //     ),
                                    //     errorElement: <Loading />,
                                    // },
                                    // {
                                    //     path: 'lost-item-exit-qrcode',
                                    //     element: (
                                    //         <ProtectedLostItemExit>
                                    //             <Page.ShopLostItemExitQrcodeView />
                                    //         </ProtectedLostItemExit>
                                    //     ),
                                    //     errorElement: <Loading />,
                                    // },
                                    {
                                        path: 're-entry',
                                        element: <Page.ShopReEntryView />,
                                        errorElement: <Loading />,
                                    },
                                    {
                                        path: 're-exit',
                                        element: <Page.ShopReExitView />,
                                        errorElement: <Loading />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/purchase',
                        element: <Page.PurchaseView />,
                        errorElement: <Loading />,
                    },
                    {
                        path: '/history',
                        element: <Page.HistoryView />,
                        errorElement: <Loading />,
                    },
                    {
                        path: '/profile',
                        children: [
                            {
                                path: '',
                                element: <Page.ProfileView />,
                                errorElement: <Loading />,
                            },
                        ],
                    },
                    {
                        path: '/logout',
                        element: <Page.LogoutView />,
                        errorElement: <Loading />,
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: <Page.LoginView />,
    },
])

export default router
