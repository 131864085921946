import { Navigate, useLocation, useParams } from 'react-router-dom'

const ProtectedExit = ({ children }: any) => {
    const param = useParams()
    const location = useLocation()
    const entryUrl = `/shops/${param.id}/entry`
    const purchaseUrl = `/purchase`

    // if (
    //     !location.state ||
    //     ![entryUrl, purchaseUrl].includes(location.state?.from)
    // ) {
    //     return <Navigate to={entryUrl} />
    // }
    return children
}

export default ProtectedExit
