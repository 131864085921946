const message = {
    invalid: `入力したデータが間違っています、再度ご確認ください。`,
    unauthenticated: '認証が必要です。ログインしてください。',
    token_failed: 'トークンが無効です。',
    success: '成功しました。',
    create_success: '登録が成功しました。',
    update_success: '更新が成功しました。',
    cancel_success_schedule: 'キャンセルが成功しました',
    error_title: 'エラー',
    upload_image_success: 'プロフィール写真の更新が成功しました',
}

export default message
