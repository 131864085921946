import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import jp from './jp/index'

i18n.use(initReactI18next).init({
    debug: false,
    fallbackLng: 'jp',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
        en: {
            translation: {
                welcome: 'Welcome to React',
            },
        },
        jp: {
            translation: jp,
        },
    },
})

export default i18n
