import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import * as API from '@/api/chargeAPI'
import { Card } from '@/interface/purchaseInterface'
import { listUserPoints } from './userPoint'

interface PurchaseProps {
    purchases: any
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    statusAddCard: 'idle' | 'loading' | 'succeeded' | 'failed'
    statusCreateIntent: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
    errorCreateIntent: any
    card?: Card
    user: any
}

const initialState = {
    purchases: [],
    status: 'idle',
    statusAddCard: 'idle',
    statusCreateIntent: 'idle',
    error: null,
    errorCreateIntent: null,
    card: {} as Card,
    user: {},
} as PurchaseProps

export const createUser = createAsyncThunk(
    'charge/createUser',
    async (payload: Object | {}) => {
        try {
            const response = await API.createUser(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

export const addCard = createAsyncThunk(
    'charge/addCard',
    async (payload: Object | {}, { rejectWithValue }) => {
        try {
            const response = await API.addCard(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const createIntent = createAsyncThunk(
    'charge/createIntent',
    async (payload: Object | {}, { rejectWithValue, dispatch }) => {
        try {
            const response = await API.createIntent(payload)
            if (response) {
                let query = {
                    all: 1,
                    'orders[0][key]': 'expired_datetime',
                    'orders[0][dir]': 'descending',
                }
                await dispatch(listUserPoints(query))
            }
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        setCard: (state, action: PayloadAction<Card | undefined>) => {
            state.card = action.payload
        },
        setStatusAddCard: (state, action: PayloadAction<any>) => {
            state.statusAddCard = action.payload
        },
        setStatusCreateIntend: (state, action: PayloadAction<any>) => {
            state.statusCreateIntent = action.payload
            state.errorCreateIntent = null
        },
    },
    extraReducers: {
        [createUser.pending as any]: (state: PurchaseProps) => {
            state.status = 'loading'
            state.error = null
        },
        [createUser.fulfilled as any]: (state: PurchaseProps, { payload }) => {
            state.status = 'succeeded'
            state.user = payload
        },
        [createUser.rejected as any]: (state, { payload }) => {
            state.status = 'failed'
            state.error = payload
        },
        [createIntent.pending as any]: (state: PurchaseProps) => {
            state.statusCreateIntent = 'loading'
            state.errorCreateIntent = null
        },
        [createIntent.fulfilled as any]: (
            state: PurchaseProps,
            { payload }
        ) => {
            state.statusCreateIntent = 'succeeded'
        },
        [createIntent.rejected as any]: (state, { payload }) => {
            state.statusCreateIntent = 'failed'
            state.errorCreateIntent = payload
        },
        [addCard.pending as any]: (state: PurchaseProps) => {
            state.statusAddCard = 'loading'
            state.error = null
        },
        [addCard.fulfilled as any]: (state: PurchaseProps, { payload }) => {
            state.statusAddCard = 'succeeded'
            state.card = payload
        },
        [addCard.rejected as any]: (state, { payload }) => {
            state.statusAddCard = 'failed'
            state.error = payload
        },
    },
})

export const { setCard, setStatusAddCard, setStatusCreateIntend } =
    purchaseSlice.actions

export default purchaseSlice.reducer
