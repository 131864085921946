import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as API from '@/api/authAPI'
import { ProfileUpdate, UserDetail } from '@/interface/userInterface'

const accessToken = localStorage.getItem('access_token')
    ? localStorage.getItem('access_token')
    : null

interface LoginProps {
    loading: boolean
    loadingProfile: boolean
    me: UserDetail
    profile: ProfileUpdate
    accessToken: string
    error: any
    success: boolean
}

const initialState = {
    loading: false,
    loadingProfile: false,
    me: {} as UserDetail,
    profile: {} as ProfileUpdate,
    accessToken,
    error: null,
    success: false,
} as LoginProps

export const userLogin = createAsyncThunk(
    'auth/login',
    async (
        {
            first_name,
            last_name,
            first_name_hiragana,
            last_name_hiragana,
            birthday,
            tel,
            gender,
            line_access_token,
            invitation_code,
        }: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await API.login({
                first_name,
                last_name,
                first_name_hiragana,
                last_name_hiragana,
                birthday,
                tel,
                gender,
                line_access_token,
                invitation_code,
            })
            localStorage.setItem('access_token', response.access_token)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const reInstall = createAsyncThunk(
    'auth/relogin',
    async ({ line_access_token }: any, { rejectWithValue }) => {
        try {
            const response = await API.reInstall({
                line_access_token,
            })
            localStorage.setItem('access_token', response.access_token)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const userLogout = createAsyncThunk('auth/logout', async () => {
    try {
        const response = await API.logout()
        localStorage.removeItem('access_token')
        return response
    } catch (error: any) {
        if (error.response && error.response.data.message) {
            return error.response.data.message
        } else {
            return error.message
        }
    }
})

export const userProfile = createAsyncThunk('auth/me', async () => {
    try {
        const response = await API.me()
        return response
    } catch (error: any) {
        if (error.response && error.response.data.message) {
            return error.response.data.message
        } else {
            return error.message
        }
    }
})

export const updateProfile = createAsyncThunk(
    'auth/profile',
    async (payload: Object | {}) => {
        try {
            const response = await API.profile(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateFavoriteId: (
            state,
            action: PayloadAction<{ shop_id: number }>
        ) => {
            state.me = {
                ...state.me,
                ...{ favorite_shop_id: action.payload.shop_id },
            }
        },
    },
    extraReducers: {
        [userLogin.pending as any]: (state: any) => {
            state.loading = true
            state.success = false
            state.error = null
        },
        [userLogin.fulfilled as any]: (state: any, { payload }) => {
            state.loading = false
            state.me = payload.me
            state.success = true
            state.accessToken = payload.access_token
        },
        [userLogin.rejected as any]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [reInstall.pending as any]: (state: any) => {
            state.loading = true
            state.success = false
            state.error = null
        },
        [reInstall.fulfilled as any]: (state: any, { payload }) => {
            state.loading = false
            state.me = payload.me
            state.success = true
            state.accessToken = payload.access_token
        },
        [reInstall.rejected as any]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [userLogout.fulfilled as any]: (state: any) => {
            state.loading = false
        },
        [userProfile.pending as any]: (state: any) => {
            state.loadingProfile = true
        },
        [userProfile.fulfilled as any]: (state: any, { payload }) => {
            state.loadingProfile = false
            state.me = payload
        },
        [userProfile.rejected as any]: (state, { payload }) => {
            state.loadingProfile = false
            state.error = payload
        },
        [updateProfile.pending as any]: (state: any) => {
            state.loading = true
        },
        [updateProfile.fulfilled as any]: (state: any, { payload }) => {
            state.loading = false
            state.profile = payload
        },
        [updateProfile.rejected as any]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    },
})

export const { updateFavoriteId } = authSlice.actions

export const profile = (state: any) => state.login.me as UserDetail

export default authSlice.reducer
