import ButtonModal from '@/components/Profile/ButtonModal'
import { Spin } from 'antd'

const Loading = () => {
    return (
        <ButtonModal isOpen={true} wrapClassName="custom-modal-button">
            <Spin />
        </ButtonModal>
    )
}

export default Loading
