import { ResponseList } from '@/interface/commonInterface'
import request from './config/request'

export const pointPackages = (payload: object | {}) => {
    const url = '/point-packages'
    return request<ResponseList, ResponseList>({
        url,
        method: 'GET',
        params: payload,
    })
}
