import React from 'react'
import { Modal } from 'antd'

interface ModalProps {
    children?: React.ReactElement | React.ReactNode
    wrapClassName?: string
    isOpen?: boolean
}

const ButtonModal: React.FC<ModalProps> = ({
    children,
    wrapClassName,
    isOpen,
}) => {
    return (
        <>
            <Modal
                title=""
                style={{ top: '30%' }}
                wrapClassName={wrapClassName}
                open={isOpen}
                maskClosable={false}
                footer={null}
                closable={false}
            >
                {children}
            </Modal>
        </>
    )
}

export default ButtonModal
