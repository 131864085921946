import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as API from '@/api/pointPackageAPI'
import { PointPackage } from '@/interface/pointPackageInterface'

interface PointPackageProps {
    pointPackages: PointPackage[]
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
}

const initialState = {
    pointPackages: [],
    status: 'idle',
    error: null,
} as PointPackageProps

export const listPointPackage = createAsyncThunk(
    'point-packages/list',
    async (payload: Object | {}) => {
        try {
            const response = await API.pointPackages(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

const pointPackageSlice = createSlice({
    name: 'pointPackage',
    initialState,
    reducers: {},
    extraReducers: {
        [listPointPackage.pending as any]: (state: PointPackageProps) => {
            state.status = 'loading'
            state.error = null
        },
        [listPointPackage.fulfilled as any]: (
            state: PointPackageProps,
            { payload }
        ) => {
            state.status = 'succeeded'
            state.pointPackages = payload
        },
        [listPointPackage.rejected as any]: (state, { payload }) => {
            state.status = 'failed'
            state.error = payload
        },
    },
})

export default pointPackageSlice.reducer

export const selectPointPackageList = (state: any) =>
    state.pointPackage.pointPackages as PointPackage[]
