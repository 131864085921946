import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import * as API from '@/api/historyAPI'

interface HistoryProps {
    histories: any
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
}

const initialState = {
    histories: [],
    status: 'idle',
    error: null,
} as HistoryProps

export const listHistory = createAsyncThunk(
    'sauna-histories/list',
    async (payload: Object | {}) => {
        try {
            const response = await API.saunaxHistories(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

const saunaHistoriesSlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setStatusHistory: (state, action: PayloadAction<any>) => {
            state.status = action.payload
        },
    },
    extraReducers: {
        [listHistory.pending as any]: (state: HistoryProps) => {
            state.status = 'loading'
            state.error = null
        },
        [listHistory.fulfilled as any]: (state: HistoryProps, { payload }) => {
            state.status = 'succeeded'
            state.histories = payload
        },
        [listHistory.rejected as any]: (state, { payload }) => {
            state.status = 'failed'
            state.error = payload
        },
    },
})

export const { setStatusHistory } = saunaHistoriesSlice.actions

export default saunaHistoriesSlice.reducer

export const selectHistoryList = (state: any) => state.history.histories
