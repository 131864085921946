import validation from './validation'
import message from './message'
import shop from './shop'
import profile from './profile'
import purchase from './purchase'

const jp = {
    login: 'ログイン',
    logout: 'ログアウト',
    mailAddress: 'メールアドレス',
    password: 'パスワード',
    create: '登録',
    back: '戻る',
    update: '更新',
    edit: '編集',
    cancel: 'キャンセル',
    search: '検索',
    clear: 'クリア',
    error: 'エラーが発生しました。',
    operate: '操作',
    quantity_item_is_showing_table_header: '{0}-{1}件を表示中（全{2}件）',
    quantity_item_is_showing_table_footer: '{0}件中 {1}-{2}件目 表示中',
    table: {
        no_data: '該当のデータがありません。',
    },
    select: {
        no_data: 'データなし',
    },
    gender: {
        male: '男性',
        female: '女性',
        no_select: '選択なし',
    },
    input_placeholder: {
        year: '西暦',
        month: '月',
        day: '日',
    },
    menu: {
        room: 'サウナを選ぶ',
        purchase: 'ストーン購入',
        history: 'サウナ歴',
        profile: 'アカウント',
        title_desc_meta: 'SAUNA XX',
    },
    browser_view: {
        mobile_version: {
            btn: 'LINEで開く',
        },
        pc_version: {
            title: 'スマートフォン版LINEでご利用になれます<',
            btn: 'スマートフォン版LINEでQRコードをスキャンしてください。',
        },
    },
    coming_soon: 'Coming Soon',
    validation,
    message,
    shop,
    profile,
    purchase,
}

export default jp
