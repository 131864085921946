import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as API from '@/api/userPointAPI'
import { UserPoint } from '@/interface/userPointInterface'

interface UserPointProps {
    userPoints: UserPoint[]
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any
}

const initialState = {
    userPoints: [],
    status: 'idle',
    error: null,
} as UserPointProps

export const listUserPoints = createAsyncThunk(
    'user-points',
    async (payload: Object, { rejectWithValue }) => {
        try {
            const response = await API.userPoints(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

const userPointSlice = createSlice({
    name: 'userPoint',
    initialState,
    reducers: {},
    extraReducers: {
        [listUserPoints.pending as any]: (state: UserPointProps) => {
            state.status = 'loading'
            state.error = null
        },
        [listUserPoints.fulfilled as any]: (
            state: UserPointProps,
            { payload }
        ) => {
            state.status = 'succeeded'
            state.userPoints = payload
        },
        [listUserPoints.rejected as any]: (state, { payload }) => {
            state.status = 'failed'
            state.error = payload
        },
    },
})

export default userPointSlice.reducer

export const selectUserPointList = (state: any) =>
    state.userPoint.userPoints as UserPoint[]
