const validation = {
    active_url: '{{0}}は有効なURLではありません',
    after: '{{0}}は{{1}}より後の日付にしてください',
    after_or_equal: '{{0}}は{{1}}以降の日付にしてください',
    alpha: '{{0}}は英字のみ有効です',
    alpha_dash: '{{0}}は「英字」「数字」「-(ダッシュ)」「_(下線)」のみ有効です',
    alpha_num: '{{0}}は「英字」「数字」のみ有効です',
    array: '{{0}}は配列タイプのみ有効です',
    before: '{{0}}は{{1}}より前の日付にしてください',
    before_or_equal: '{{0}}は{{1}}以前の日付にしてください',
    between: {
        numeric: '{{0}}は {{1}} ～ {2} までの数値まで有効です',
        file: '{{0}}は {{1}} ～ {2} キロバイトまで有効です',
        string: '{{0}}は {{1}} ～ {2} 文字まで有効です',
        array: '{{0}}は {{1}} ～ {2} 個まで有効です',
    },
    boolean: '{{0}}の値は true もしくは false のみ有効です',
    confirmed: '{{0}}を確認用と一致させてください',
    date: '{{0}}を有効な日付形式にしてください',
    date_format: '{{0}}を{{1}}書式と一致させてください',
    different: '{{0}}を{{1}}と違うものにしてください',
    digits: '{{0}}は:digits桁のみ有効です',
    digits_between: '{{0}}は{{1}} ～ {2}桁のみ有効です',
    dimensions: '{{0}}ルールに合致する画像サイズのみ有効です',
    distinct: '{{0}}に重複している値があります',
    email: '{{0}}メールアドレスの書式のみ有効です',
    exists: '{{0}}無効な値です',
    file: '{{0}}アップロード出来ないファイルです',
    filled: '{{0}}値を入力してください',
    gt: {
        numeric: '{{0}}は{{1}}より大きい必要があります。',
        file: '{{0}}は{{1}}キロバイトより大きい必要があります。',
        string: '{{0}}は{{1}}文字より多い必要があります。',
        array: '{{0}}には{{1}} 個より多くの項目が必要です。',
    },
    gte: {
        numeric: '{{0}}は{{1}}以上である必要があります。',
        file: '{{0}}は{{1}}キロバイト以上である必要があります。',
        string: '{{0}}は{{1}}文字以上である必要があります。',
        array: '{{0}}には{{1}}個以上の項目が必要です。',
    },
    image: {
        type: '画像は「jpg」「png」「jpeg」のみ有効です',
        max: '{{0}}は{{1}}MB以下のファイルのみ有効です',
    },
    in: '{{0}}無効な値です',
    in_array: '{{0}}は{{1}}と一致する必要があります',
    integer: '{{0}}は整数のみ有効です',
    ip: '{{0}}IPアドレスの書式のみ有効です',
    ipv4: '{{0}}IPアドレス(IPv4)の書式のみ有効です',
    ipv6: '{{0}}IPアドレス(IPv6)の書式のみ有効です',
    json: '{{0}}正しいJSON文字列のみ有効です',
    lt: {
        numeric: '{{0}}は{{1}}未満である必要があります。',
        file: '{{0}}は{{1}}キロバイト未満である必要があります。',
        string: '{{0}}は{{1}}文字未満である必要があります。',
        array: '{{0}}は{{1}}未満の項目を持つ必要があります。',
    },
    lte: {
        numeric: '{{0}}は{{1}}以下である必要があります。',
        file: '{{0}}は{{1}}キロバイト以下である必要があります。',
        string: '{{0}}は{{1}}文字以下である必要があります。',
        array: '{{0}}は{{1}}以上の項目を持つ必要があります。',
    },
    max: {
        numeric: '{{0}}は{{1}}以下のみ有効です',
        file: '{{0}}は{{1}}KB以下のファイルのみ有効です',
        string: '{{0}}は{{1}}文字以下のみ有効です',
        array: '{{0}}は{{1}}個以下のみ有効です',
    },
    mimes: '{{0}}は {{1}}s タイプのみ有効です',
    mimetypes: '{{0}}は {{1}}s タイプのみ有効です',
    min: {
        numeric: '{{0}}は{{1}}以上のみ有効です',
        file: '{{0}}は{{1}}KB以上のファイルのみ有効です',
        string: '{{0}}は{{1}}文字以上のみ有効です',
        array: '{{0}}は{{1}}個以上のみ有効です',
    },
    not_in: '{{0}}無効な値です',
    not_regex: 'The {{0}} format is invalid.',
    numeric: '{{0}}は数字のみ有効です',
    present: '{{0}} が存在しません',
    regex: '{{0}} 無効な値です',
    required: '{{0}}は必須です',
    required_if: '{{0}}は{{1}}が{{1}}には必須です',
    required_unless: '{{0}}は{{1}}が{{1}}sでなければ必須です',
    required_with: '{{0}}は{{1}}sが入力されている場合は必須です',
    required_with_all: '{{0}}は{{1}}sが入力されている場合は必須です',
    required_without: '{{0}}は{{1}}sが入力されていない場合は必須です',
    required_without_all: '{{0}}は{{1}}sが入力されていない場合は必須です',
    same: '{{0}}は{{1}}と同じ場合のみ有効です',
    size: {
        numeric: '{{0}}は{{1}}のみ有効です',
        file: '{{0}}は{{1}}KBのみ有効です',
        string: '{{0}}は{{1}}文字のみ有効です',
        array: '{{0}}は{{1}}個のみ有効です',
    },
    string: '{{0}}は文字列のみ有効です',
    timezone: '{{0}}正しいタイムゾーンのみ有効です',
    unique: '{{0}}は既に存在します',
    already_exist: '{{0}}は既に登録されています',
    uploaded: '{{0}}アップロードに失敗しました',
    url: '{{0}}は正しいURL書式のみ有効です',
    tel_format: '電話番号は英数字とハイフンのみ入力してください。',
    password: {
        format: 'パスワードは半角英数字記号8文字以上入力してください。',
        confirm: 'パスワードとパスワード（再確認）が一致しません。',
        format_with_label: '{{0}}は半角英数字記号8文字以上入力してください。',
    },
    check_one: '少なくとも 1 つのアクティビティ タイプを選択してください',
    date_future: '{{0}}には、今日より前の日付を指定してください',
}

export default validation
