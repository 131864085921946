import { ResponseList } from '@/interface/commonInterface'
import request from './config/request'

export const userPoints = (payload: object | {}) => {
    const url = '/user-points'
    return request<ResponseList, ResponseList>({
        url,
        method: 'GET',
        params: payload,
    })
}
