import React from 'react'

const LoginView = React.lazy(() => import('./Auth/LoginView'))
const LogoutView = React.lazy(() => import('./Auth/LogoutView'))
const ShopView = React.lazy(() => import('./Shop/ShopView'))
const NoMatch = React.lazy(() => import('./NoMatch'))
const ShopDetailView = React.lazy(() => import('./Shop/ShopDetailView'))
const ShopEntryView = React.lazy(() => import('./Shop/ShopEntryView'))
const ShopExitView = React.lazy(() => import('./Shop/ShopExitView'))
const ShopLostItemEntryView = React.lazy(
    () => import('./Shop/ShopLostItemEntryView')
)
const ShopLostItemExitView = React.lazy(
    () => import('./Shop/ShopLostItemExitView')
)
const ShopLostItemExitQrcodeView = React.lazy(
    () => import('./Shop/ShopLostItemExitQrcodeView')
)
const ShopReEntryView = React.lazy(() => import('./Shop/ShopReEntryView'))
const ShopReExitView = React.lazy(() => import('./Shop/ShopReExitView'))
const HistoryView = React.lazy(() => import('./History/HistoryView'))
const PurchaseView = React.lazy(() => import('./Purchase/PurchaseView'))
const ProfileView = React.lazy(() => import('./Profile/ProfileView'))

export {
    LoginView,
    LogoutView,
    NoMatch,
    ShopView,
    ShopDetailView,
    ShopEntryView,
    ShopReEntryView,
    ShopReExitView,
    ShopExitView,
    ShopLostItemEntryView,
    ShopLostItemExitView,
    ShopLostItemExitQrcodeView,
    HistoryView,
    PurchaseView,
    ProfileView,
}
