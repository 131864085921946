import React from 'react'
import {
    useLocation,
    useMatches,
    useNavigate,
    useNavigation,
} from 'react-router-dom'
import Icon4 from '@/assets/imgs/ico-1.png'
import Icon4Hover from '@/assets/imgs/ico-1-hover.png'
import Icon2 from '@/assets/imgs/ico-3.png'
import Icon2Hover from '@/assets/imgs/ico-3-hover.png'
import Icon3 from '@/assets/imgs/ico-2.png'
import Icon3Hover from '@/assets/imgs/ico-2-hover.png'
import Icon1 from '@/assets/imgs/ico-4.png'
import Icon1Hover from '@/assets/imgs/ico-4-hover.png'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/app/hooks'
import { profile } from '@/stores/login'

const AppNav: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()
    const me = useAppSelector(profile)
    const goUrl = (url: string) => {
        navigate(url)
        localStorage.setItem('recharge_url', url)
    }

    return (
        <nav className="menu-fixed">
            <div className="list">
                <div className="pb-5">
                    <div
                        className={`menu-icon ${
                            location.pathname.includes('/shops') ||
                            location.pathname === '/'
                                ? 'active'
                                : ''
                        } shop-now`}
                        onClick={() => goUrl('/shops')}
                    >
                        <img src={Icon1} alt="" className="icon" />
                        <img
                            src={Icon1Hover}
                            alt=""
                            className="icon icon-hover"
                        />
                        <p className="txt">{t('menu.room')}</p>
                        {me.current_transaction_detail_id && (
                            <span className="badge">NOW</span>
                        )}
                    </div>
                </div>
                <div className="pb-5">
                    <div
                        className={`menu-icon ${
                            location.pathname.includes('/purchase')
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => goUrl('/purchase')}
                    >
                        <img src={Icon2} alt="" className="icon" />
                        <img
                            src={Icon2Hover}
                            alt=""
                            className="icon icon-hover"
                        />
                        <p className="txt">{t('menu.purchase')}</p>
                    </div>
                </div>
                <div className="pb-5">
                    <div
                        className={`menu-icon ${
                            location.pathname.includes('/history')
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => goUrl('/history')}
                    >
                        <img src={Icon3} alt="" className="icon" />
                        <img
                            src={Icon3Hover}
                            alt=""
                            className="icon icon-hover"
                        />
                        <p className="txt">{t('menu.history')}</p>
                    </div>
                </div>
                <div className="pb-5">
                    <div
                        className={`menu-icon ${
                            location.pathname.includes('/profile')
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => goUrl('/profile')}
                    >
                        <img src={Icon4} alt="" className="icon" />
                        <img
                            src={Icon4Hover}
                            alt=""
                            className="icon icon-hover"
                        />
                        <p className="txt">{t('menu.profile')}</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default AppNav
