import { ResponseList } from '@/interface/commonInterface'
import { Shop } from '@/interface/shopInterface'
import request from './config/request'

export const shops = (payload: object | {}) => {
    const url = '/shops'
    return request<ResponseList, ResponseList>({
        url,
        method: 'GET',
        params: payload,
    })
}

export const shop = (id?: string | string[] | number) => {
    const url = `/shops/${id}`
    return request<Shop, Shop>({
        url,
        method: 'GET',
    })
}

export const shopFavorite = (id?: string | string[] | number) => {
    const url = `/shops/${id}/favorite`
    return request<Shop, Shop>({
        url,
        method: 'GET',
    })
}

export const entryKey = (id?: string | string[] | number) => {
    const url = `/shops/${id}/entry-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const entryByButton = () => {
    const url = '/shops/entry-by-button'
    return request<any, any>({
        url,
        method: 'POST',
    })
}

export const exitByButton = () => {
    const url = '/shops/exit-by-button'
    return request<any, any>({
        url,
        method: 'POST',
    })
}

export const entryInfo = (id?: string | string[] | number) => {
    const url = `/shops/${id}/entry-info`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const exitKey = (id?: string | string[] | number) => {
    const url = `/shops/${id}/exit-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const reEntryKey = (id?: string | string[] | number) => {
    const url = `/shops/${id}/late-entry-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}
export const reExitKey = (id?: string | string[] | number) => {
    const url = `/shops/${id}/late-exit-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const rejectEtiquette = () => {
    const url = `/reject-etiquette`
    return request<any, any>({
        url,
        method: 'POST',
    })
}

export const completeEtiquette = () => {
    const url = `/complete-etiquette`
    return request<any, any>({
        url,
        method: 'POST',
    })
}

export const FavoriteShop = (payload: object | {}) => {
    const url = '/favorite-shop'
    return request<ResponseList, ResponseList>({
        url,
        method: 'POST',
        data: payload,
    })
}

export const lostItemEntry = (id?: string | string[] | number) => {
    const url = `/shops/${id}/lost-item-entry-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const lostItemEntryInfo = (id?: string | string[] | number) => {
    const url = `/shops/${id}/lost-item-entry-info`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const lostItemExit = (id?: string | string[] | number) => {
    const url = `/shops/${id}/lost-item-exit-key`
    return request<any, any>({
        url,
        method: 'GET',
    })
}

export const updateEntryKeyStatus = () => {
    const url = `/update-entry-key-status`
    return request<any, any>({
        url,
        method: 'GET',
    })
}
