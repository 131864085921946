import { useAppSelector } from '@/app/hooks'
import { profile } from '@/stores/login'
import {
    TRANSACTION_TYPE_LOST_ITEM,
    TRANSACTION_TYPE_NORMAL,
} from '@/utils/constants'
import { Spin } from 'antd'
import { Navigate } from 'react-router-dom'

const ProtectedShop = ({ children }: any) => {
    const me = useAppSelector(profile)
    const { loadingProfile } = useAppSelector((state) => state.login)

    if (!me.transaction_details) {
        return children
    }

    if (
        Object.keys(me).length &&
        Number(me.transaction_details?.type) === TRANSACTION_TYPE_NORMAL
    ) {
        return (
            <Navigate to={`/shops/${me.transaction_details?.shop_id}/exit`} />
        )
    }
    if (
        Object.keys(me).length &&
        Number(me.transaction_details?.type) === TRANSACTION_TYPE_LOST_ITEM
    ) {
        return (
            <Navigate
                to={`/shops/${me.transaction_details?.shop_id}/lost-item-exit`}
            />
        )
    }

    return (
        <Spin tip="Loading..." spinning={loadingProfile}>
            {children}
        </Spin>
    )
}

export default ProtectedShop
